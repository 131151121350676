body {
  font-family: Arial, sans-serif;
  background-color: #181818;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-image: url('https://raw.githubusercontent.com/monkaCode/valolytics-images/refs/heads/main/backgrounds/premier.png');
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(3px);
}

.container {
  background-color: #2e2e2e;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
}

h2 {
  color: #f0f0f0;
}

img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

button.login {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  color: #f0f0f0;
  font-size: 16px;
  margin: 10px auto;
}

button img {
  height: 24px;
  width: 24px;
  object-fit: contain;
  margin-right: 8px;
}

button.discord {
  background-color: #5865F2;
}

button.riot {
  background-color: #EB0029;
  margin-top: 20px;
  min-width: 150px;
}

button.twitter {
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.user-info {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-info h2 {
  font-size: 20px;
  margin: 10px 0;
}

.user-info p {
  color: #f0f0f0;
  margin: 5px 0;
}

.riot-information {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.riot-gameName {
  color: #f0f0f0;
  font-size: 20px;
}

.riot-tagLine {
  margin-left: 5px;
  color: #8d8d8d;
  font-size: 16px;
}

.discord-username {
  color: #f0f0f0;
  font-size: 20px;
}

.twitter-username {
  color: #f0f0f0;
  font-size: 20px;
}

.twitter-information,
.vlr-information,
.discord-information,
.goto-lft {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.twitter-information img,
.vlr-information img,
.discord-information img,
.riot-information img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 20%;
}

.vlr-information input {
  background-color: #161616;
  color: #f0f0f0;
  border: none;
  border-radius: 5px;
  padding: 8px;
  width: 7rem;
  font-size: 18px;
}

.display-name {
  margin-bottom: 1rem;
}

.display-name input {
  background-color: #161616;
  color: #f0f0f0;
  border: none;
  border-radius: 5px;
  padding: 8px;
  width: 12rem;
  font-size: 18px;
}

.vlr-information button {
  background-color: transparent;
  color: #f0f0f0;
  border: none;
  margin-left: 5px;
  cursor: pointer;
}

input.approved {
  border: 1px solid #00ff00;
}

input.rejected {
  border: 1px solid #ff0000;
}

input.pending {
  border: 1px solid #ffcc00;
}


.pointer-on-hover:hover {
  cursor: pointer;
}

.logout {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;
  margin-bottom: -30px;
}

h3 {
  color: #777777;
  font-weight: 100;
}

.error {
  background-color: #e43d3d;
  color: white;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 7%;
  margin: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.error-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -20px;
}

.error-header h2 {
  margin-left: 5px;
}

.loading-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-dots span {
  font-size: 24px;
  margin: 0 2px;
  animation: loading 1.5s infinite;
}

.hline {
  width: 100%;
  height: 1px;
  background-color: #b8b8b8;
  margin: 10px 0;
}

.goto-lft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem auto 0 auto;
  padding: 0.5rem;
  background-color: #405CFF;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  gap: 10px;
}

.goto-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.goto-header {
  font-size: 20px;
  font-weight: 600;
}

@keyframes loading {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.loading-dots span:nth-child(1) {
  animation-delay: 0s;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}